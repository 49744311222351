import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const Selector = ({
  Id,
  Value,
  Disabled,
  Name,
  Lista,
  Required,
  Estado,
  SetEstado,
}) => {
  const CambioSeleccion = (e) => {
    SetEstado({ ...Estado, [e.target.name]: e.target.value });
  };

  const ListaDesplegable = Lista.map((value, index) => (
    <MenuItem key={index} value={value} defaultValue={''}>
      {value}
    </MenuItem>
  ));

  return (
    <FormControl size='small'>
      <InputLabel>{Id}</InputLabel>
      <Select
        value={Estado[Name]}
        onChange={CambioSeleccion}
        defaultValue={Value}
        id={Id}
        name={Name}
        //required={Required}
        label={Id}
        inputProps={{ disabled: Disabled }}
      >
        <MenuItem value="" defaultValue={''}>
          <em>Seleccionar</em>
        </MenuItem>

        {ListaDesplegable}
      </Select>
    </FormControl>
  );
};
export default Selector;
