import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const CalendarioRange = ({ Estado, SetEstado }) => {
  const RangoFechas = (e) => {
    SetEstado({
      ...Estado,
      FechaInicio: dayjs(e[0]).format('YYYY-MM-DD'),
      FechaFinal: dayjs(e[1]).format('YYYY-MM-DD'),
    });
  };
  return (
    <RangePicker onChange={RangoFechas} format={'DD/MM/YYYY'} />
  );
};
export default CalendarioRange;
