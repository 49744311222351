import Swal from 'sweetalert2';

const SwalALert = (DatosMensaje) => {
  if (DatosMensaje.Info !== 'Correcto') {
    Swal.fire({
      icon: DatosMensaje.Info === 'Incorrecta' ? 'question' : 'error',
      title: 'Oops...',
      text: DatosMensaje.Estado,
    });
  }
  if (DatosMensaje.Info === 'CorrectoRegistro') {
    Swal.fire({
      icon: 'success',
      title: 'Ok',
      text: DatosMensaje.Estado,
    });
  }
  if (DatosMensaje.Info === 'Actualización') {
    Swal.fire({
      icon: 'success',
      title: 'Ok',
      text: DatosMensaje.Estado,
      confirmButtonText: 'Ok',
      
    }).then((response)=> {
      window.location.href = '/consultausuarios';
    });
  }
  if (DatosMensaje.Info === 'Eliminación') {
    Swal.fire({
      icon: 'success',
      title: 'Ok',
      text: DatosMensaje.Estado,
      confirmButtonText: 'Ok',
    }).then((response) => {
      window.location.href = '/consultausuarios';
    });
  }
};
export default SwalALert;
