import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { RegistrarFormulario } from './Endpoints/EndPoints';

const useFormulario = async (DatosFormulario) => {
  var EstadoEnvio = '';
  await axios
    .post(
      RegistrarFormulario,
      {
        DatosFormulario,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((response) => {
      if (response.data.Status === 200) {
        SwalALert({
          Info: 'CorrectoRegistro',
          Estado: response.data.Info,
        });
        EstadoEnvio = 'Ok';
      } else {
        SwalALert({ Info: response.data, Estado: response.data.Error });
        EstadoEnvio = 'Error';
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return EstadoEnvio;
};

export default useFormulario;
