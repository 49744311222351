export const Light = {
  body: '#FFFFFF',
  text: '#202020',
  bodyRgba: '255,255,255',
  textRgba: '32,32,32',
  carouselColor: '#EEEDDE',
  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em',
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxL: '3em',
  fontxxxl: '4em',
  fontButton: '0.875em',
  navHeight: '5rem',
  whitebg: '#7250FF',
  bg: 'rgb(255,255,255)',
  bgAlpha: 'rgb(255,255,255,3)',
  bg2: 'rgb(245,245,245)',
  bg3: 'rgb(230,230,230)',
  primary: 'rgb(52,131,235)',
  bg4: '#9247FC',
  bgtototal: '#EDF3FB',
  bgtgderecha: '#fff',
  barrascroll: '#a8a7a7',

  gray900: '#000000',
  gray800: '#1f1f1f',
  gray700: '#2e2e2e',
  gray600: '#313131',
  gray500: '#969593',
  gray400: '#a6a6a6',
  gray300: '#bdbbb7',
  gray200: '#f1f1f1',
  gray100: '#ffffff',

  grenn500: '#F9516E',
  olive500: '#e3ffa8',

  white: '#ffffff',

  textprimary: '#2a2828',
  textsecondary: '#f1f1f1',
  texttertiary: '969593',

  lightbackground: 'linaer-gradient(-255deg, #E3FDF5 0%, #FFE6FA 100%)',
  lightcheckbox: '#fce100',
};

export const Dark = {
  body: '#202020',
  bodyRgba: '32,32,32',
  textRgba: '255,255,255',
  carouselColor: '#EEEDDE',
  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em',
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxL: '3em',
  fontxxxl: '4em',
  fontButton: '0.875em',
  navHeight: '5rem',
  whitebg: '#7522e6s',
  bg: 'rgb(15,15,15)',
  bgAlpha: 'rgb(0,0,0,.3)',
  bg2: 'rgb(30,30,30)',
  bg3: 'rgb(50,50,50)',
  text: 'rgb(210,210,210)',
  primary: 'rgb(52,131,235)',
  bg4: '#9247FC',
  bgtototal: '#21252b',
  bgtgderecha: '#212552b',
  barrascroll: '#A18Aff',

  gray900: '#000000',
  gray800: '#1f1f1f',
  gray700: '#2e2e2e',
  gray600: '#313131',
  gray500: '#969593',
  gray400: '#a6a6a6',
  gray300: '#bdbbb7',
  gray200: '#f1f1f1',
  gray100: '#ffffff',

  grenn500: '#F9516E',
  olive500: '#e3ffa8',

  white: '#ffffff',

  textprimary: '#ffffff',
  textsecondary: '#f1f1f1',
  texttertiary: '969593',

  lightbackground: 'linaer-gradient(to bottom, rgba(255,255,255, 0.15) 0%, rgba(0,0,0,0,15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0% rgba(0,0,0,0.40)120%) #989898',
  lightcheckbox: '#7575757',
};
