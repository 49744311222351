import axios from 'axios';
import { useState } from 'react';
import SwalALert from '../Componentes/SweetAlert';
import styled from 'styled-components';
import { Button } from '@mui/material';
import CalendarioRange from '../Componentes/CalendarioRangos';
import Selector from '../Componentes/Selector';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import { BotonCss } from '../Componentes/Boton';
import { ConsultaPacientesRango } from '../ConsultaApi/Endpoints/EndPoints';

export function ConsultaPacientes() {
  const [DataApi, setDataApi] = useState([]);
  const [DataConsulta, setDataConsulta] = useState({
    FechaInicio: '',
    FechaFinal: '',
    Tipo: '',
  });

  const ConsultaUsu = () => {
    if (
      DataConsulta.FechaFinal !== '' &&
      DataConsulta.FechaInicio !== '' &&
      DataConsulta.Tipo !== ''
    ) {
      axios
        .post(ConsultaPacientesRango, {
          DataConsulta,
        })
        .then((Response) => {
          if (Response.data.Resultado) {
            setDataApi(Response.data.Resultado);
          } else {
            SwalALert({
              Info: Response.data.Mensaje,
              Estado: 'No Hay Datos Para Las Fechas Seleccionadas.',
            });
          }
        })
        .catch((err) => {
          SwalALert({ Info: err.code, Estado: 'No hay acceso al servidor.' });
        })
        .finally(() => {});
    }
  };

  const Desbloquear = (e) => {
    localStorage.setItem('Data', JSON.stringify(DataApi[e.target.id]));
    window.location.href = '#/ninosdetalles';
  };

  var ListaAcceso = ['Adolescentes', 'Adultos', 'Informe', 'Niños'];

  return (
    <Fragment>
      <Container>
        <CalendarioRange Estado={DataConsulta} SetEstado={setDataConsulta} />
        <Selector
          Id="Tipo De Consulta"
          Disabled={false}
          Name="Tipo"
          Lista={ListaAcceso}
          Value={''}
          Required={true}
          Estado={DataConsulta}
          SetEstado={setDataConsulta}
        ></Selector>
        <BotonCss variant="contained" onClick={ConsultaUsu}>
          Consulta Pacientes
        </BotonCss>
        <Tabla>
          <table className=" table table-sm table table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Fecha Consulta</th>
                <th scope="col">Identificación</th>
                <th scope="col">Nombre</th>
                <th scope="col">Motivo</th>
                <th scope="col">Acción</th>
              </tr>
            </thead>
            <tbody>
              {DataApi.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{dayjs(value.Fecha).format('DD/MM/YYYY')}</td>
                    <td>{value.Cedula}</td>
                    <td>{value.NombreCompleto}</td>
                    <td>{value.Motivo}</td>
                    <td>
                      {
                        <Button
                          id={index}
                          onClick={Desbloquear}
                          color="primary"
                        >
                          Ver
                        </Button>
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Tabla>
      </Container>
    </Fragment>
  );
}
const Container = styled.div`
  display: grid;
  grid-template-columns: 250px 250px 150px;
  grid-template-rows: 40px 20px 40px;
  margin-top: 10px;
  margin-left: 100px;
  gap: 25px;
  grid-template-areas:
    'area1 area1 area1'
    'area2 area2 area2 area2';
`;
const Tabla = styled.div`
  display: grid;
  grid-area: area2;
  margin-left: -880px;
  margin-top: -400px;
  width: 1620%;
  justify-items: center;
  grid-template-rows: 2px;
  font-size: 14px;
  text-align: center;
  font-weight: 100;
  color: gray;
`;
