import styled from 'styled-components';
import ImgSurcos from '../Images/Surcos.png';
import { Variables } from '../Style/Variables';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';
import MoodSharpIcon from '@mui/icons-material/MoodSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import AssignmentSharpIcon from '@mui/icons-material/AssignmentSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';
import FeedIcon from '@mui/icons-material/Feed';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

import { NavLink } from 'react-router-dom';
import { useContext } from 'react';
import { ThemeContext } from '../App';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const cooUsername = cookies.get('acceso');

export function MenuLateral({ sidebarOpen, setsidebarOpen }) {
  const ModSidebarOpen = () => {
    if (cooUsername) {
      setsidebarOpen(!sidebarOpen);
    }
  };

  const { setTheme, Theme } = useContext(ThemeContext);
  const CambiarTema = () => {
    setTheme((Theme) => (Theme === 'light' ? 'dark' : 'light'));
  };
  const LinksArray = [
    {
      label: 'Registro Adultos',
      icon: <GroupAddSharpIcon />,
      to: '/adultos',
    },
    {
      label: 'Registro Niños',
      icon: <MoodSharpIcon />,
      to: '/ninos',
    },

    {
      label: 'Registro Adolescentes',
      icon: <PersonSharpIcon />,
      to: '/adolescentes',
    },
    {
      label: 'Asesoría',
      icon: <FollowTheSignsIcon />,
      to: '/formularioasesor',
    },
    {
      label: 'Informe Psicológico',
      icon: <AssignmentSharpIcon />,
      to: '/informe',
    },
    {
      label: 'Consulta Pacientes',
      icon: <FeedIcon />,
      to: '/consultapacientes',
    },
  ];
  const ListSecondary = [
    {
      label: 'Configuración',
      icon: <SettingsSharpIcon />,
      to: '/configuracion',
    },
    {
      label: 'Cerrar Sesión',
      icon: <CancelSharpIcon />,
      to: '/salir',
    },
  ];

  const Usuarios = {
    label: 'Registro Usuarios',
    icon: <GroupSharpIcon />,
    to: '/registrousuarios',
  };
  const ConsultaUsuarios = {
    label: 'Consulta Usuarios',
    icon: <GroupSharpIcon />,
    to: '/consultausuarios',
  };
  return (
    <div className="MenuInicio">
      <Container isOpen={sidebarOpen} themeUse={Theme}>
        <button className="sidebarButtom" onClick={ModSidebarOpen}>
          <ArrowBackIosNewSharpIcon />
        </button>
        <div className="Logo">
          <div className="imgcontent">
            <img src={ImgSurcos} alt="Logo Surcos"></img>
          </div>
          <h2>Corporación Surcos</h2>
        </div>
        {LinksArray.map(({ label, to, icon }) => (
          <div className="LinkContainer" key={label}>
            <NavLink
              to={to}
              className={({ isActive }) => `Links${isActive ? ` active` : ``}`}
            >
              <div className="LinkIcon">{icon}</div>
              {sidebarOpen && <span>{label}</span>}
            </NavLink>
          </div>
        ))}
        <Divider></Divider>
        {cooUsername === 'Administrador' && (
          <div className="LinkContainer" key={Usuarios.label}>
            <NavLink
              to={Usuarios.to}
              className={({ isActive }) => `Links${isActive ? ` active` : ``}`}
            >
              <div className="LinkIcon">{Usuarios.icon}</div>
              {sidebarOpen && <span>{Usuarios.label}</span>}
            </NavLink>
          </div>
        )}

        {cooUsername === 'Administrador' && (
          <div className="LinkContainer" key={ConsultaUsuarios.label}>
            <NavLink
              to={ConsultaUsuarios.to}
              className={({ isActive }) => `Links${isActive ? ` active` : ``}`}
            >
              <div className="LinkIcon">{ConsultaUsuarios.icon}</div>
              {sidebarOpen && <span>{ConsultaUsuarios.label}</span>}
            </NavLink>
          </div>
        )}
        <Divider></Divider>
        {ListSecondary.map(({ label, to, icon }) => (
          <div className="LinkContainer" key={label}>
            <NavLink
              to={to}
              className={({ isActive }) => `Links${isActive ? ` active` : ``}`}
            >
              <div className="LinkIcon">{icon}</div>
              {sidebarOpen && <span>{label}</span>}
            </NavLink>
          </div>
        ))}
        <Divider></Divider>
        <div className="Themecontent">
          {sidebarOpen && <span className="titletheme">Modo Oscuro</span>}
          <div className="ToogleContent">
            <div className="grid theme-container">
              <div className="content">
                <div className="demo">
                  <label className="switch">
                    <input
                      type="checkbox"
                      className="theme-swither"
                      onClick={CambiarTema}
                    ></input>
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
const Container = styled.div`
  background: ${(props) => props.theme.bg};
  color: ${({ theme }) => theme.text};
  position: sticky;
  padding-top: 8px;
  padding-right: 10px;
  .sidebarButtom {
    position: absolute;
    top: ${Variables.xxlSpacing};
    right: -18px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${(props) => props.theme.bgtgderecha};
    box-shadow: 0 0 4px ${(props) => props.theme.bg3},
      0 0 7px ${(props) => props.theme.bg};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
    transform: ${({ isOpen }) => (isOpen ? `initial` : `rotate(180deg)`)};
    border: none;
    letter-spacing: inherit;
    color: inherit;
    font-size: inherit;
    text-align: inherit;
    padding: 0;
    font-family: inherit;
    outline: none;
  }
  .Logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: ${Variables.lgSpacing};
    .imgcontent {
      display: flex;
      img {
        max-width: 100%;
        height: auto;
      }
      cursor: pointer;
      transition: all 0.3s;
      transform: ${({ isOpen }) => (isOpen ? `scale(0.4)` : `scale(0.5)`)};
    }
    h2 {
      display: ${({ isOpen }) => (!isOpen ? `none` : `block`)};
    }
  }
  .LinkContainer {
    margin: 8px 0;
    padding: 0 15%;
    :hover {
      background: ${(props) => props.theme.bg3};
    }
    .Links {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: calc(${Variables.smSpacing}-2px) 0;
      color: ${(props) => props.theme.text};

      .LinkIcon {
        padding: calc(${Variables.smSpacing}) ${Variables.smSpacing};
        display: flex;
        svg {
          font-size: 25px;
        }
      }
      &.active {
        .LinkIcon {
          svg {
            color: ${(props) => props.theme.bg4};
          }
        }
      }
    }
  }
  .Themecontent {
    display: flex;
    align-items: center;
    .titletheme {
      display: block;
      padding: 10px;
      font-weight: 600;
      opacity: ${({ isOpen }) => (isOpen ? `1` : `0`)};
      transition: all 0.3s;
      white-space: nowrap;
      overflow: hidden;
    }
    .ToogleContent {
      margin: ${({ isOpen }) => (isOpen ? ` auto 40px` : `auto 15px`)};
      width: 36px;
      height: 20px;
      border-radius: 10px;
      transition: all 0.3s;
      position: relative;
      .theme-container {
        background-blend-mode: multiply, multiply;
        transition: 0.4s;
        .grid {
          display: grid;
          justify-items: center;
          align-content: center;
          height: 100vh;
          width: 100vh;
          font-family: 'Lato', sans-serif;
        }
        .demo {
          font-size: 32px;
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            .theme-swither {
              opacity: 0;
              width: 0;
              height: 0;
              &:checked + .slider::before {
                left: 4px;
                content: '🌝';
                transform: translateX(26px);
              }
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: ${({ themeUse }) =>
                themeUse === 'light'
                  ? Variables.lightcheckbox
                  : Variables.checkbox};
              transition: 0.4s;
              &::before {
                position: absolute;
                content: '🌞';
                height: 0px;
                width: 0px;
                left: -10px;
                top: 16px;
                line-height: 0px;
                transition: 0.4s;
              }
              &.round {
                border-radius: 34px;
                &::before {
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${(props) => props.theme.bg3};
  margin: ${Variables.lgSpacing} 0;
`;
