export const Variables = {
  sidebarWidth: `300px`,
  sidebarwidthWidthInicial: `10vw`,
  smSpacing: `8px`,
  mdSpacing: `16px`,
  lgSpacing: `24px`,
  xlSpacing: `32px`,
  xxlSpacing: `48px`,
  borderRadius: `6px`,

  lightbackground: `linear.gradient(.225deg, #E3FDF5 0%, #FFE6FA 100&)`,
  lightcheckbox: `#ffef39`,

  darkbackground: `linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at to center, rgba(255,255,255,0.40), 0%, rgba(0,0,0,0.40) 120%) #989898`,
  darkcheckbox: `757575`,
  background: `linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at to center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40)120%) 989898`,
  checkbox: `292929`,
};