import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { AgregarUsuario } from './Endpoints/EndPoints';

const useFormularioAdmin = async (DatosAdminFormulario) => {
  var EstadoEnvio = '';

  if (!Object.values(DatosAdminFormulario).includes('')) {
    if (
      DatosAdminFormulario.Contraseña ===
      DatosAdminFormulario.ConfirmarContraseña
    ) {
      await axios
        .post(
          AgregarUsuario,
          {
            DatosAdminFormulario,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .then((response) => {
          if (response.data.Status === 200) {
            SwalALert({
              Info: 'CorrectoRegistro',
              Estado: response.data.Info,
            });
            EstadoEnvio = 'Ok';
          } else {
            SwalALert({ Info: response.data, Estado: response.data.Error });
            EstadoEnvio = 'Error';
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      SwalALert({ Info: '', Estado: 'Las contraseñas No Coinciden.' });
    }
  } else {
    SwalALert({ Info: '', Estado: 'Faltan campos por rellenar.' });
  }
  return EstadoEnvio;
};

export default useFormularioAdmin;
