import RutasFrontend from './Rutas/RutasFrontend';
import { Light, Dark } from './Style/Themes';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import React, { useState } from 'react';
import { MenuLateral } from './Formularios/MenuLateral';
import { HashRouter} from 'react-router-dom';
import './Style/DiseñoLateral.css'

export const ThemeContext = React.createContext(null);

function App() {
  const [SidebarOpen, setSidebarOpen] = useState(false);
  const [Theme, setTheme] = useState('light');
  const ThemeStyle = Theme === 'light' ? Light : Dark;

  return (
    <>
      <ThemeContext.Provider value={{ setTheme, Theme }}>
        <ThemeProvider theme={ThemeStyle}>
          <HashRouter>
            <Container
              className={SidebarOpen ? 'sidedarState active' : 'Prueba'}
            >
              <MenuLateral
                sidebarOpen={SidebarOpen}
                setsidebarOpen={setSidebarOpen}
              />
              <RutasFrontend />
            </Container>
          </HashRouter>
        </ThemeProvider>
      </ThemeContext.Provider>
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 90px auto;
  background: ${({ theme }) => theme.body};
  transition: all 0.3s;
  &.active {
    grid-template-columns: 300px auto;
  }
  color: ${({ theme }) => theme.text};
`;

export default App;
