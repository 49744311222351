import { useState } from 'react';
import styled from 'styled-components';
import es from 'dayjs/locale/es';
import Selector from '../Componentes/Selector';
import Calendario from '../Componentes/Calendario';
import Input from '../Componentes/TextField';
import dayjs from 'dayjs';
import { Button } from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import useFormulario from '../ConsultaApi/RegistrarFormularios.js';
import Cookies from 'universal-cookie';

export function Adolescente() {
  const cookies = new Cookies();
  const cooUsername = cookies.get('username');
  const AutoComplete = 'off';
  const valoresIniciales = {
    Fecha: dayjs().locale(es),
    FechaNacimiento: dayjs().locale(es).add(-17, 'years'),
    Cedula: '',
    NombreCompleto: '',
    Edad:
      dayjs().locale(es).year() - dayjs().locale(es).add(-17, 'years').year(),
    Posicion: '',
    Nivel: '',
    CentroEducativo: '',
    Direccion: '',
    Celular: '',
    NombreMadre: '',
    OcupacionMadre: '',
    HorariosMadre: '',
    EdadMadre: '',
    NombrePadre: '',
    OcupacionPadre: '',
    HorariosPadre: '',
    EdadPadre: '',
    HermanoUno: '',
    HermanoDos: '',
    EdadDos: '',
    EdadUno: '',
    HermanoEstudioUno: '',
    HermanoEstudioDos: '',
    Genograma: '',
    Evaluacion: '',
    Autor: '',
    Acude: '',
    Actitud: '',
    Sintomas: '',
    DesdeCuando: '',
    Motivo: '',
    Psicólogo: '',
    Interpretacion: '',
    Evolucion: '',
    Tratamientos: '',
    Expectativas: '',
    Concepcion: '',
    Periodo: '',
    Temperamento: '',
    Lactancia: '',
    Experiencia: '',
    Pubertad: '',
    Demanda: '',
    Identidad: '',
    ActitudFamiliar: '',
    Dinamica: '',
    Consumo: '',
    Somaticas: '',
    AntecedentesFamiliares: '',
    '¿Cuando?': '',
    '¿Quién?': '',
    Conducta: '',
    SituacionesVitales: '',
    ObservacionesEntrevista: '',
    Desarrollo: '',
    Formulario: 'Adolescentes',
  };

  const [FormularioAdolescentes, setFormularioAdolescentes] =
    useState(valoresIniciales);

  const OpcionesSelector = ['Si', 'No'];
  const ListaAcceso = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const ListaNivel = ['Primaria', 'Secundaria', 'Educación Superior'];

  const FormularioHistoria = [
    {
      Label: 'Síntomas',
      Id: 'Sintomas',
    },
    {
      Label: 'Desde Cuando',
      Id: 'DesdeCuando',
    },
    {
      Label: 'Por Qué (Interpretación)',
      Id: 'Interpretacion',
    },
    {
      Label: 'Evolución Del Problema',
      Id: 'Evolucion',
    },
    {
      Label: 'Tratamientos / Resultados',
      Id: 'Tratamientos',
    },
    {
      Label: 'Expectativas Actuales',
      Id: 'Expectativas',
    },
  ];

  const FormularioFinal = [
    {
      Text: 'Conducta No Verbal Observada',
      Id: 'Conducta',
      Label: 'Movimiento, Cambios en la Mirada',
    },
    {
      Text: 'Situaciones Vitales Estresantes',
      Id: 'SituacionesVitales',
      Label: 'Situaciones Vitales',
    },
    {
      Text: 'Observaciones Durante La Entrevista',
      Id: 'ObservacionesEntrevista',
      Label: 'Observaciones',
    },
  ];

  const FormularioSelector = [
    {
      Text: 'Enfermedades Somáticas e Intervenciones Quirúrgicas',
      Id: 'Somaticas',
      Label: '¿Cuando?',
    },
    {
      Text: 'Antecedentes Familiares',
      Id: 'AntecedentesFamiliares',
      Label: '¿Quién?',
    },
  ];

  const AntecedentesPersonales = [
    {
      Label: 'Concepción',
      Id: 'Concepcion',
    },
    {
      Label: 'Periodo Neonatal',
      Id: 'Periodo',
    },
    {
      Label: 'Temperamento',
      Id: 'Temperamento',
    },
    {
      Label: 'Lactancia Y Alimentación Infantil',
      Id: 'Lactancia',
    },
  ];

  const FormularioInput = [
    {
      Text: 'Desarrollo Evolutivo',
      Label: 'Aspectos Generales (Psicomotor, Lenguaje)',
      Id: 'Desarrollo',
    },
    {
      Text: 'Escolaridad',
      Label: 'Experiencia Estudios',
      Id: 'Experiencia',
    },
    {
      Text: 'Sexualidad',
      Label: 'Pubertad (Menarquía)',
      Id: 'Pubertad',
    },
    {
      Text: '',
      Label: 'Demanda De Información',
      Id: 'Demanda',
    },
    {
      Text: '',
      Label: 'Identidad Sexual / Otros Aspectos',
      Id: 'Identidad',
    },
    {
      Text: '',
      Label: 'Actitud Familiar',
      Id: 'ActitudFamiliar',
    },
    {
      Text: 'Convivencia',
      Label: 'Dinámica Familiar',
      Id: 'Dinamica',
    },
    {
      Text: 'Habitos E Intereses',
      Label: 'Consumo Alcohol, Drogas',
      Id: 'Consumo',
    },
  ];

  const ReiniciarFormulario = () => {
    setFormularioAdolescentes(valoresIniciales);
  };

  const EnvioData = () => {
    useFormulario(FormularioAdolescentes).then((response) => {
      if (response === 'Ok') {
        ReiniciarFormulario();
      }
    });
  };

  return (
    <Container>
      <h1>Anamnesis Adolescente</h1>
      <h6 className="Usuario">Usuario: {cooUsername}</h6>
      <div className="Calendario">
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha"
          Name="Fecha"
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
        ></Calendario>
      </div>
      <div className="DatosPersonales">
        <Input
          Label="N° Documento"
          Id="Cedula"
          Name="Cedula"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Nombre Completo"
          Id="Nombre"
          Name="NombreCompleto"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha Nacimiento"
          Name="FechaNacimiento"
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
        ></Calendario>
        <Input
          Label="Edad"
          Id="Edad"
          Name="Edad"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Selector
          Id="Posicion Hermanos"
          Disabled={false}
          Name="Posicion"
          Lista={ListaAcceso}
          Value={''}
          Required={true}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
        ></Selector>
        <Selector
          Id="Nivel De Escolaridad"
          Disabled={false}
          Name="Nivel"
          Lista={ListaNivel}
          Value={''}
          Required={true}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
        ></Selector>
        <Input
          Label="Centro Educativo"
          Id="CentroEducativo"
          Name="CentroEducativo"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Dirección"
          Id="Direccion"
          Name="Direccion"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Celular"
          Id="Celular"
          Name="Celular"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
      </div>

      <h3>Datos Familiares</h3>
      <div className="ClaseFormulario">
        <Input
          Label="Nombre Del Padre"
          Id="NombrePadre"
          Name="NombrePadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Edad Del Padre"
          Id="EdadPadre"
          Name="EdadPadre"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Ocupación Del Padre"
          Id="OcupacionPadre"
          Name="OcupacionPadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Horarios Del Padre"
          Id="HorariosPadre"
          Name="HorariosPadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Nombre De La Madre"
          Id="NombreMadre"
          Name="NombreMadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Edad De La Madre"
          Id="EdadMadre"
          Name="EdadMadre"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Ocupación De la Madre"
          Id="OcupacionMadre"
          Name="OcupacionMadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Horarios De La Madre"
          Id="HorariosMadre"
          Name="HorariosMadre"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Nombre Hermano/a"
          Id="HermanoUno"
          Name="HermanoUno"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Edad Hermano/a"
          Id="EdadUno"
          Name="EdadUno"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Estudio / Ocupación"
          Id="HermanoEstudioUno"
          Name="HermanoEstudioUno"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Nombre Hermano/a"
          Id="HermanoDos"
          Name="HermanoDos"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>

        <Input
          Label="Edad Hermano/a"
          Id="EdadDos"
          Name="EdadDos"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>

        <Input
          Label="Estudio / Ocupación"
          Id="HermanoEstudioDos"
          Name="HermanoEstudioDos"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Genograma (Constelación Familiar/ Profesión y Ocupación"
          Id="Genograma"
          Name="Genograma"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={true}
        ></Input>
      </div>
      <h3>Motivo De La Consulta</h3>
      <div className="ClaseFormulario">
        <Input
          Label="Evaluación Solicitada Por:"
          Id="Evaluacion"
          Name="Evaluacion"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Motivo:"
          Id="Motivo"
          Name="Motivo"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Autor De La Evaluación:"
          Id="Autor"
          Name="Autor"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Quien Acude:"
          Id="Acude"
          Name="Acude"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
        <Input
          Label="Con Qué Actitud:"
          Id="Actitud"
          Name="Actitud"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdolescentes}
          SetEstado={setFormularioAdolescentes}
          Multiline={false}
        ></Input>
      </div>
      <h3>Historia Evolutiva Del Problema</h3>
      <div className="ClaseFormulario">
        {FormularioHistoria.map(({ Label, Id }) => (
          <Input
            key={Id}
            Label={Label}
            Id={Id}
            Name={Id}
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioAdolescentes}
            SetEstado={setFormularioAdolescentes}
            Multiline={false}
          ></Input>
        ))}
      </div>
      <h3>AntecedentesPersonales</h3>
      <div className="ClaseFormulario">
        {AntecedentesPersonales.map(({ Label, Id }) => (
          <Input
            key={Id}
            Label={Label}
            Id={Id}
            Name={Id}
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioAdolescentes}
            SetEstado={setFormularioAdolescentes}
            Multiline={false}
          ></Input>
        ))}
      </div>
      {FormularioInput.map(({ Id, Label, Text }) => {
        if ({ Text }) {
          return (
            <div className="ClaseFormulario" key={Id}>
              <h3 key={Id & 2}>{Text}</h3>
              <Input
                key={Id}
                Label={Label}
                Id={Id}
                Name={Id}
                Focus="true"
                Required={true}
                Type={'text'}
                autoComplete={AutoComplete}
                Estado={FormularioAdolescentes}
                SetEstado={setFormularioAdolescentes}
                Multiline={false}
              ></Input>
            </div>
          );
        } else {
          return (
            <Input
              key={Id}
              Label={Label}
              Id={Id}
              Name={Id}
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={FormularioAdolescentes}
              SetEstado={setFormularioAdolescentes}
              Multiline={false}
            ></Input>
          );
        }
      })}
      {FormularioSelector.map(({ Id, Text, Label }) => (
        <div className="ClaseFormularioUno" key={Id}>
          <h5>{Text}</h5>
          <Selector
            Id={Id}
            key={Id}
            Disabled={false}
            Name={Id}
            Lista={OpcionesSelector}
            Value={''}
            Required={true}
            Estado={FormularioAdolescentes}
            SetEstado={setFormularioAdolescentes}
          ></Selector>
          <Input
            Label={Label}
            Id={Label}
            Name={Label}
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioAdolescentes}
            SetEstado={setFormularioAdolescentes}
            Multiline={false}
          ></Input>
        </div>
      ))}
      {FormularioFinal.map(({ Id, Label, Text }) => {
        return (
          <div className="ClaseFormulario" key={Id}>
            <h5 key={Id & 2}>{Text}</h5>
            <Input
              key={Id}
              Label={Label}
              Id={Id}
              Name={Id}
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={FormularioAdolescentes}
              SetEstado={setFormularioAdolescentes}
              Multiline={true}
            ></Input>
          </div>
        );
      })}
      <div className="Botones">
        <Button
          variant="contained"
          type="submit"
          onClick={EnvioData}
          color="success"
          endIcon={<SaveSharpIcon />}
          name="Boton"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          type="reset"
          color="error"
          onClick={ReiniciarFormulario}
          endIcon={<CancelPresentationSharpIcon />}
        >
          Cancelar
        </Button>
      </div>
    </Container>
  );
}
const Container = styled.div`
  .Calendario {
    display: grid;
    width: 100%;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
  }
  .DatosPersonales {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .ClaseFormularioUno {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  h1 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  .Usuario {
    text-align: right;
  }
`;
