import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Calendario = ({ Formato, Name, Estado, SetEstado, Label, Disabled }) => {
  const ActualizarFecha = (e) => {
    if (Estado.Edad) {
      SetEstado({
        ...Estado,
        [Name]: e,
        Edad: Estado.Fecha.year() - Estado['FechaNacimiento'].year(),
      });
    } else {
      SetEstado({
        ...Estado,
        [Name]: e,
      });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={Estado[Name]}
        onChange={ActualizarFecha}
        format={Formato}
        label={Label}
        name={Name}
        disabled={Disabled}
      />
    </LocalizationProvider>
  );
};
export default Calendario;
