import { useState } from 'react';
import styled from 'styled-components';
import Calendario from '../Componentes/Calendario';
import Input from '../Componentes/TextField';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import Selector from '../Componentes/Selector';
import { Button } from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import useFormulario from '../ConsultaApi/RegistrarFormularios.js';
import Cookies from 'universal-cookie';

export function Adultos() {

  const cookies = new Cookies();
  const cooUsername = cookies.get('username');
  const AutoComplete = 'off';
  const valoresIniciales = {
    Fecha: dayjs().locale(es),
    FechaNacimiento: dayjs().locale(es).add(-20, 'years'),
    Cedula: '',
    NombreCompleto: '',
    Edad:
      dayjs().locale(es).year() - dayjs().locale(es).add(-20, 'years').year(),
    Posicion: '',
    Nivel: '',
    Direccion: '',
    Celular: '',
    CelularEmergencia: '',
    ContactoEmergencia: '',
    EstadoCivil: '',
    Hijos: '',
    Motivo: '',
    Antecedentes: '',
    Enfermedades: '',
    ObservacionesBiologicas: '',
    Medicacion: '',
    Situacion: '',
    Laboral: '',
    Familiar: '',
    Estudios: '',
    Particularidades: '',
    Habitos: '',
    Observaciones: '',
    Psicólogo: '',
    Formulario: 'Adultos',
  };

  const [FormularioAdultos, setFormularioAdultos] = useState(valoresIniciales);
  const ListaAcceso = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const ListaNivel = ['Primaria', 'Secundaria', 'Educación Superior'];
  const ListaEstadoCivil = ['Soltero', 'Casado', 'Viudo', 'Unión Libre'];

  const ReiniciarFormulario = () => {
    setFormularioAdultos(valoresIniciales);
  };

  const EnvioData = () => {
    useFormulario(FormularioAdultos).then((response) => {
      if (response === 'Ok') {
        ReiniciarFormulario();
      }
    });
  };

  return (
    <Container>
      <h1>Anamnesis Adultos</h1>
      <h6 className="Usuario">Usuario: {cooUsername}</h6>
      <div className="Calendario">
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha"
          Name="Fecha"
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Calendario>
      </div>
      <div className="ClaseFormulario">
        <Input
          Label="N° Cédula"
          Id="Cedula"
          Name="Cedula"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Input
          Label="Nombre Completo"
          Id="NombreCompleto"
          Name="NombreCompleto"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha Nacimiento"
          Name="FechaNacimiento"
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Calendario>
        <Input
          Label="Edad"
          Id="Edad"
          Name="Edad"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Selector
          Id="Posicion Hermanos"
          Disabled={false}
          Name="Posicion"
          Lista={ListaAcceso}
          Value={''}
          Required={true}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Selector>
        <Selector
          Id="Nivel"
          Disabled={false}
          Name="Nivel"
          Lista={ListaNivel}
          Value={''}
          Required={true}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Selector>
        <Input
          Label="Dirección"
          Id="Direccion"
          Name="Direccion"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Input
          Label="Celular"
          Id="Celular"
          Name="Celular"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Input
          Label="Celular De Emergencia"
          Id="CelularEmergencia"
          Name="CelularEmergencia"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Input
          Label="Contacto De Emergencia"
          Id="ContactoEmergencia"
          Name="ContactoEmergencia"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={false}
        ></Input>
        <Selector
          Id="Estado Civil"
          Disabled={false}
          Name="EstadoCivil"
          Lista={ListaEstadoCivil}
          Value={''}
          Required={true}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Selector>
        <Selector
          Id="Hijos"
          Disabled={false}
          Name="Hijos"
          Lista={ListaAcceso}
          Value={''}
          Required={true}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
        ></Selector>
      </div>
      <div className="ClaseFormulario">
        <Input
          Label="Motivo De La Consulta"
          Id="Motivo"
          Name="Motivo"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>

        <Input
          Label="Antecedentes Familiares"
          Id="Antecedentes"
          Name="Antecedentes"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Enfermedades"
          Id="Enfermedades"
          Name="Enfermedades"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Observaciones Biologicas"
          Id="ObservacionesBiológicas"
          Name="ObservacionesBiológicas"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Medicación"
          Id="Medicacion"
          Name="Medicacion"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Situación Afectiva"
          Id="Situacion"
          Name="Situacion"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Situación Laboral"
          Id="Laboral"
          Name="Laboral"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>

        <Input
          Label="Situación Familiar"
          Id="Familiar"
          Name="Familiar"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Estudios (Dificultades Académicas)"
          Id="Estudios"
          Name="Estudios"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Particularidades De La Adolescencia"
          Id="Particularidades"
          Name="Particularidades"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Habitos E Intereses"
          Id="Habitos"
          Name="Habitos"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
        <Input
          Label="Observaciones"
          Id="Observaciones"
          Name="Observaciones"
          Focus="true"
          Required={true}
          autoComplete={AutoComplete}
          Estado={FormularioAdultos}
          SetEstado={setFormularioAdultos}
          Multiline={true}
        ></Input>
      </div>
      <div className="Botones">
        <Button
          variant="contained"
          type="submit"
          onClick={EnvioData}
          color="success"
          endIcon={<SaveSharpIcon />}
          name="Boton"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          type="reset"
          color="error"
          onClick={ReiniciarFormulario}
          endIcon={<CancelPresentationSharpIcon />}
        >
          Cancelar
        </Button>
      </div>
    </Container>
  );
}
const Container = styled.div`
  .Calendario {
    display: grid;
    width: 100%;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  h1 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  .Usuario {
    text-align: right;
  }
`;
