import { Fragment, useState } from 'react';
import Input from '../Componentes/TextField';
import Selector from '../Componentes/Selector';
import { Button } from '@mui/material';
import { ActualizarUsuario } from '../ConsultaApi/ActualizarUsuarios';
import { EliminarUsuario } from '../ConsultaApi/EliminarUsuario';
import VisibilityIcon from '@mui/icons-material/Visibility';

export function UsuarioDetalle() {
  const AutoComplete = 'off';
  const DataApi = JSON.parse(localStorage.getItem('Data'));
  const [DataUsuario, setDataUsuario] = useState(DataApi);

  const ListaAcceso = ['Usuario', 'Administrador'];
  const ListaEstado = ['Activo', 'Inactivo'];

  const Actualizar = () => {
    if (DataUsuario !== DataApi) {
      ActualizarUsuario(DataUsuario).then((response) => {
        if (response === 'Ok') {
        }
      });
    }
  };

  const Eliminar = () => {
    EliminarUsuario(DataUsuario).then((response) => {
      if (response === 'Ok') {
      }
    });
  };
  return (
    <Fragment>
      <div className="FormularioAdmin">
        <div className="BordeFormulario">
          <div className="FormularioColumnas">
            <Input
              Label="N° Cédula"
              Id="Cedula"
              Name="cedula"
              Focus="true"
              Required={true}
              Type={'number'}
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Nombre"
              Id="Nombre"
              Name="nombre"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
            <Input
              Label="Apellido"
              Id="Apellido"
              Name="apellido"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Usuario"
              Id="Usuario"
              Name="usuario"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
            <Input
              Label="Correo"
              Id="Correo"
              Name="correo"
              Focus="true"
              Required={true}
              Type={'email'}
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Contraseña"
              Focus="true"
              Required={true}
              Name="contraseña"
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
              endIcon={<VisibilityIcon/>}
            ></Input>
            <Input
              Label="Confirmar Contraseña"
              Focus="true"
              Required={true}
              Name="ConfirmarContraseña"
              autoComplete={AutoComplete}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Selector
              Id="Acceso"
              Disabled={false}
              Name="acceso"
              Lista={ListaAcceso}
              Value={''}
              Required={true}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Selector>
            <Selector
              Id="Estado"
              Disabled={false}
              Name="estado"
              Lista={ListaEstado}
              Value={ListaEstado[0]}
              Required={true}
              Estado={DataUsuario}
              SetEstado={setDataUsuario}
            ></Selector>
          </div>
        </div>

        <div className="Botones">
          <Button
            variant="outlined"
            type="submit"
            onClick={Actualizar}
            color="success"
            //endIcon={< />}
            name="Boton"
          >
            Actualizar
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={Eliminar}
            color="success"
            //endIcon={< />}
            name="Boton"
          >
            Eliminar
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
