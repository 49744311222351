import { Fragment, useState } from 'react';
import styled from 'styled-components';
import Input from '../Componentes/TextField';
import Calendario from '../Componentes/Calendario';
import Selector from '../Componentes/Selector';
import Cookies from 'universal-cookie';
import { Button } from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';

export function NinosDetalle() {
  const cookies = new Cookies();
  const cooUsername = cookies.get('username');
  const AutoComplete = 'off';
  const DataApi = JSON.parse(localStorage.getItem('Data'));
  const [FormularioNinos, setFormularioNinos] = useState(DataApi);

  const OpcionesSelector = ['Si', 'No'];
  const ListaAcceso = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const ListaNivel = ['Primaria', 'Secundaria', 'Educación Superior'];
  /*const Actualizar = () => {
    if (FormularioNinos !== DataApi) {
      ActualizarUsuario(FormularioNinos).then((response) => {
        if (response === 'Ok') {
        }
      });
    }
  };

  const Eliminar = () => {
    EliminarUsuario(DataUsuario).then((response) => {
      if (response === 'Ok') {
      }
    });
  };*/

  const FormularioInputNinos = [
    {
      Label: 'Síntomas',
      Id: 'Sintomas',
    },
    {
      Label: 'Evolución Del Problema',
      Id: 'Evolucion',
    },
    {
      Label: 'Tratamientos / Resultados',
      Id: 'Tratamientos',
    },
    {
      Label: 'Expectativas Actuales',
      Id: 'Expectativas',
    },

    {
      Label: 'Concepción',
      Id: 'Concepcion',
    },
    {
      Label: 'Preferencia De Sexo',
      Id: 'Preferencia',
    },
    {
      Label: 'Observación',
      Id: 'ObservacionesConcepcion',
    },
    {
      Text: 'Embarazo',
      Label: 'Únicos/Gemelos',
      Id: 'Unicos',
    },
    {
      Label: 'Duración',
      Id: 'Duracion',
    },
    {
      Label: 'Enfermedades Durante...',
      Id: 'EnfermedadesDurante',
    },
    {
      Label: 'Consumo De Farmacos',
      Id: 'Farmacos',
    },
    {
      Label: 'Observaciones',
      Id: 'ObservacionesEmbarazo',
    },
    {
      Text: 'Nacimiento',
      Label: 'Eutócico Normal O Distócico',
      Id: 'Normal',
    },
    {
      Label: 'Reflejo Normal - Succión Normal',
      Id: 'Reflejo',
    },
    {
      Label: 'Peso',
      Id: 'Peso',
    },
    {
      Label: 'Observación',
      Id: 'ObservacionNacimiento',
    },
    {
      Text: 'Periodo Neonatal',
      Label: 'Complicaciones',
      Id: 'Complicaciones',
    },
    {
      Label: 'Observación',
      Id: 'ObservaciónPeriodo',
    },
    {
      Text: 'Lactancia Y Alimentación',
      Label: 'Observación',
      Id: 'ObservaciónLactancia',
    },
    {
      Text: 'Desarrollo Evolutivo',
      Label: 'Aspectos Generales',
      Id: 'Aspectos',
    },
    {
      Text: 'Escolaridad',
      Label: 'Adaptación Al Ambiente Escolar',
      Id: 'Adaptacion',
    },
    {
      Text: 'Convivencia',
      Label: 'Dinamica Familiar',
      Id: 'Dinamica',
    },
  ];

  const FormularioSelector = [
    {
      Text: 'Intervenciones Quirúrgicas',
      Id: 'Somaticas',
      Label: '¿Cuando?',
    },
    {
      Text: 'Antecedentes Familiares',
      Id: 'AntecedentesFamiliares',
      Label: '¿Quién?',
    },
  ];

  const FormularioFinal = [
    {
      Id: 'Conducta',
      Label: 'Situaciones Vitales Estresantes',
    },
    {
      Id: 'SituacionesVitales',
      Label: 'Observaciones Durante La Entrevista',
    },
  ];
  return (
    <Fragment>
      <Container>
        <h1>Anamnesis Niños</h1>
        <h6 className="Usuario">Usuario: {cooUsername}</h6>
        <div className="Calendario"></div>
        <div className="ClaseFormulario">
          <Input
            Label="N° Documento"
            Id="Cedula"
            Name="Cedula"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Nombre Completo"
            Id="Nombre"
            Name="NombreCompleto"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>

          <Input
            Label="Edad"
            Id="Edad"
            Name="Edad"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Selector
            Id="Posicion"
            Disabled={false}
            Name="Posicion"
            Lista={ListaAcceso}
            Value={''}
            Required={true}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
          ></Selector>
          <Selector
            Id="Nivel"
            Disabled={false}
            Name="Nivel"
            Lista={ListaNivel}
            Value={''}
            Required={true}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
          ></Selector>
          <Input
            Label="Centro Educativo"
            Id="CentroEducativo"
            Name="CentroEducativo"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Dirección"
            Id="Direccion"
            Name="Direccion"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Celular"
            Id="Celular"
            Name="Celular"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
        </div>
        <h3>Datos Familiares</h3>
        <div className="ClaseFormulario">
          <Input
            Label="Nombre Del Padre"
            Id="NombrePadre"
            Name="NombrePadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Edad Del Padre"
            Id="EdadPadre"
            Name="EdadPadre"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Ocupación Del Padre"
            Id="OcupacionPadre"
            Name="OcupacionPadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Horarios Del Padre"
            Id="HorariosPadre"
            Name="HorariosPadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Nombre De La Madre"
            Id="NombreMadre"
            Name="NombreMadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Edad De La Madre"
            Id="EdadMadre"
            Name="EdadMadre"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Ocupación De la Madre"
            Id="OcupacionMadre"
            Name="OcupacionMadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Horarios De La Madre"
            Id="HorariosMadre"
            Name="HorariosMadre"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Nombre Hermano/a"
            Id="HermanoUno"
            Name="HermanoUno"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Edad Hermano/a"
            Id="EdadUno"
            Name="EdadUno"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Estudio / Ocupación"
            Id="HermanoEstudioUno"
            Name="HermanoEstudioUno"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Nombre Hermano/a"
            Id="HermanoDos"
            Name="HermanoDos"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>

          <Input
            Label="Edad Hermano/a"
            Id="EdadDos"
            Name="EdadDos"
            Focus="true"
            Required={true}
            Type={'number'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>

          <Input
            Label="Estudio / Ocupación"
            Id="HermanoEstudioDos"
            Name="HermanoEstudioDos"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Genograma (Constelación Familiar/ Profesión y Ocupación"
            Id="Genograma"
            Name="Genograma"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={true}
          ></Input>
        </div>
        <h3>Motivo De La Consulta</h3>
        <div className="ClaseFormulario">
          <Input
            Label="Evaluación Solicitada Por:"
            Id="Evaluacion"
            Name="Evaluacion"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Motivo:"
            Id="Motivo"
            Name="Motivo"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Autor De La Evaluación:"
            Id="Autor"
            Name="Autor"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
          <Input
            Label="Quien Acude:"
            Id="Acude"
            Name="Acude"
            Focus="true"
            Required={true}
            Type={'text'}
            autoComplete={AutoComplete}
            Estado={FormularioNinos}
            SetEstado={setFormularioNinos}
            Multiline={false}
          ></Input>
        </div>
        <h3>Historia Evolutiva Del Problema</h3>
        <div className="ClaseFormulario">
          {FormularioInputNinos.map(({ Id, Label }) => {
            return (
              <Input
                key={Id}
                Label={Label}
                Id={Id}
                Name={Id}
                Focus="true"
                Required={true}
                Type={'text'}
                autoComplete={AutoComplete}
                Estado={FormularioNinos}
                SetEstado={setFormularioNinos}
                Multiline={false}
              ></Input>
            );
          })}
        </div>
        {FormularioSelector.map(({ Id, Text, Label }) => (
          <div key={Id}>
            <h3>{Text}</h3>
            <Selector
              Id={Id}
              key={Id}
              Disabled={false}
              Name={Id}
              Lista={OpcionesSelector}
              Value={''}
              Required={true}
              Estado={FormularioNinos}
              SetEstado={setFormularioNinos}
            ></Selector>
          </div>
        ))}

        {FormularioFinal.map(({ Id, Label, Text }) => {
          return (
            <div key={Id}>
              <h3 key={Id & 2}>{Text}</h3>
              <Input
                key={Id}
                Label={Label}
                Id={Id}
                Name={Id}
                Focus="true"
                Required={true}
                Type={'text'}
                autoComplete={AutoComplete}
                Estado={FormularioNinos}
                SetEstado={setFormularioNinos}
                Multiline={true}
              ></Input>
            </div>
          );
        })}
        <div className="Botones">
          <Button
            variant="contained"
            type="submit"
            color="success"
            endIcon={<SaveSharpIcon />}
            name="Boton"
          >
            Actualizar
          </Button>
          <Button variant="contained" type="reset" color="error">
            Cancelar
          </Button>
        </div>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  .Calendario {
    display: grid;
    width: 100%;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  h1 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  .Usuario {
    text-align: right;
  }
`;
