import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const BotonCss = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 13,
  padding: '6px 12px',
  border: '1px solid',
});
