import dayjs from 'dayjs';
import styled from 'styled-components';
import es from 'dayjs/locale/es';
import { useState } from 'react';
import Calendario from '../Componentes/Calendario';
import Selector from '../Componentes/Selector';
import Input from '../Componentes/TextField';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import useFormulario from '../ConsultaApi/RegistrarFormularios.js';
import { Button } from '@mui/material';
import Cookies from 'universal-cookie';
import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { ConsultaInforme } from '../ConsultaApi/Endpoints/EndPoints';

export function InformePsicologico() {
  const cookies = new Cookies();
  const cooUsername = cookies.get('username');
  const AutoComplete = 'off';
  const valoresIniciales = {
    Fecha: dayjs().locale(es),
    FechaNacimiento: dayjs().locale(es).add(-25, 'years'),
    Cedula: '',
    NombreCompleto: '',
    Edad:
      dayjs().locale(es).year() - dayjs().locale(es).add(-25, 'years').year(),
    Posicion: '',
    Nivel: '',
    Direccion: '',
    Evaluador: '',
    Motivo: '',
    Familiar: '',
    Psicólogo: '',
    AntecedentesFamiliares: '',
    Conducta: '',
    Morbilidad: '',
    Tecnicas: '',
    Emocional: '',
    Social: '',
    Corporal: '',
    Hipotesis: '',
    Conclusiones: '',
    Recomendaciones: '',
    Formulario: 'Informe',
  };

  const ListaAcceso = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const [FormularioInforme, setFormularioInforme] = useState(valoresIniciales);
  const ListaNivel = ['Primaria', 'Secundaria', 'Educación Superior'];

  const ListaInput = [
    {
      Text: ' II Motivo De La Consulta',
      Label: 'Motivo',
      Id: 'Motivo',
    },
    {
      Text: 'III Observación De La Conducta',
      Label: 'Observación De La Conducta',
      Id: 'Conducta',
    },
    {
      Text: 'IV Antecedentes Familiares',
      Label: 'Antecedentes',
      Id: 'AntecedentesFamiliares',
    },
    {
      Text: 'V Antecedentes De Morbilidad Personales Y/O Familiares',
      Label: 'Antecedentes De Morbilidad Personales',
      Id: 'Morbilidad',
    },
    {
      Text: 'VI Técnicas Aplicadas',
      Label: 'Técnicas Aplicadas',
      Id: 'Tecnicas',
    },
    {
      Text: 'VII Esferas De Funcionamiento',
      Label: 'Familiar',
      Id: 'Familiar',
    },
    {
      Text: '',
      Label: 'Emocional',
      Id: 'Emocional',
    },
    {
      Text: '',
      Label: 'Social',
      Id: 'Social',
    },
    {
      Text: '',
      Label: 'Físico Corporal',
      Id: 'Corporal',
    },
    {
      Text: 'VIII Hipótesis Diagnostica',
      Label: 'Físico Corporal',
      Id: 'Hipotesis',
    },
    {
      Text: 'IX Conclusiones',
      Label: 'Conclusiones',
      Id: 'Conclusiones',
    },
    {
      Text: 'X Recomendaciones',
      Label: 'Recomendaciones',
      Id: 'Recomendaciones',
    },
  ];

  const ReiniciarFormulario = () => {
    setFormularioInforme(valoresIniciales);
    setDisableInput(true);
  };
  const ConsultarUsuario = () => {
    axios
      .post(ConsultaInforme, {
        Data: FormularioInforme.Cedula,
      })
      .then((response) => {
        setDisableInput(false);
        if (response.data.nombre) {
          setFormularioInforme({
            ...FormularioInforme,
            NombreCompleto: response.data.nombre,
          });
        } else {
          setDisableInput(false);
          SwalALert({
            Info: 'No Se Encuentra Registrado.',
            Estado: 'No Se Encuentra Registrado.',
          });
        }
      });
  };

  const EnvioData = () => {
    useFormulario(FormularioInforme).then((response) => {
      if (response === 'Ok') {
        ReiniciarFormulario();
      }
    });
  };

  const [DisableInput, setDisableInput]= useState(true)
  return (
    <Container>
      <h3>Informe Psicológico</h3>
      <h6 className="Usuario">Usuario: {cooUsername}</h6>
      <div className="Calendario">
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha"
          Name="Fecha"
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
        ></Calendario>
        <Calendario
          Formato="DD/MM/YYYY"
          Label="Fecha Nacimiento"
          Name="FechaNacimiento"
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
        ></Calendario>
      </div>
      <div className="ClaseFormulario">
        <Input
          Label="N° Documento"
          Id="Cedula"
          Name="Cedula"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Multiline={false}
        ></Input>
        <Button onClick={ConsultarUsuario}>Consultar</Button>
      </div>
      <div className="ClaseFormulario">
        <Input
          Label="Nombre Completo"
          Id="Nombre"
          Name="NombreCompleto"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Multiline={false}
          Disabled={DisableInput}
        ></Input>
        <Input
          Label="Edad"
          Id="Edad"
          Name="Edad"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Multiline={false}
        ></Input>

        <Selector
          Id="Posicion"
          Name="Posicion"
          Lista={ListaAcceso}
          Value={''}
          Required={true}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Disabled={DisableInput}
        ></Selector>
        <Selector
          Id="Nivel"
          Disabled={false}
          Name="Nivel"
          Lista={ListaNivel}
          Value={''}
          Required={true}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
        ></Selector>
        <Input
          Label="Dirección"
          Id="Direccion"
          Name="Direccion"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Multiline={false}
        ></Input>
        <Input
          Label="Evaluador"
          Id="Evaluador"
          Name="Evaluador"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={FormularioInforme}
          SetEstado={setFormularioInforme}
          Multiline={false}
        ></Input>
      </div>
      <div className="ClaseFormulario">
        {ListaInput.map(({ Id, Label, Text }) => {
          if ({ Text }) {
            return (
              <div key={Id}>
                <h3 key={Id & 2}>{Text}</h3>
                <Input
                  key={Id}
                  Label={Label}
                  Id={Id}
                  Name={Id}
                  Focus="true"
                  Required={true}
                  Type={'text'}
                  autoComplete={AutoComplete}
                  Estado={FormularioInforme}
                  SetEstado={setFormularioInforme}
                  Multiline={true}
                ></Input>
              </div>
            );
          } else {
            return (
              <Input
                key={Id}
                Label={Label}
                Id={Id}
                Name={Id}
                Focus="true"
                Required={true}
                Type={'text'}
                autoComplete={AutoComplete}
                Estado={FormularioInforme}
                SetEstado={setFormularioInforme}
                Multiline={true}
              ></Input>
            );
          }
        })}
      </div>
      <div className="Botones">
        <Button
          variant="contained"
          type="submit"
          onClick={EnvioData}
          color="success"
          endIcon={<SaveSharpIcon />}
          name="Boton"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          type="reset"
          color="error"
          onClick={ReiniciarFormulario}
          endIcon={<CancelPresentationSharpIcon />}
        >
          Cancelar
        </Button>
      </div>
    </Container>
  );
}
const Container = styled.div`
  .Calendario {
    display: grid;
    width: 100%;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  .Usuario {
    text-align: right;
  }
`;
