import '../Style/login.css';
import InicioSesion from '../ConsultaApi/InicioLogin';

const LoginInicio = () => {
  return (
    <div className='Inicio'>
      <div className="box">
        <span className="borderline"></span>
        <form name="FormularioLogin">
          <h2>SURCOS</h2>
          <div className="inputBox">
            <input
              type="text"
              required
              name="Usuario"
              autoComplete="off"
            ></input>
            <span>Usuario</span>
            <i></i>
          </div>
          <div className="inputBox">
            <input
              type="password"
              required
              name="Password"
            ></input>
            <span>Contraseña</span>
            <i></i>
          </div>
          <div className="links">
            <a href="www.google.com">Recuperar Contraseña</a>
          </div>
          <button type="submit" onClick={InicioSesion}>
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};
export default LoginInicio;
