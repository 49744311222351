import { TextField } from '@mui/material';

const Input = ({
  Label,
  Focus,
  Required,
  Type,
  Id,
  Name,
  autoComplete,
  Estado,
  SetEstado,
  Multiline,
  Disabled,
}) => {
  const Changed = (e) => {
    SetEstado({ ...Estado, [e.target.name]: e.target.value });
  };

  return (
    <TextField
      label={Label}
      id={Id}
      name={Name}
      required={Required}
      autoComplete={autoComplete}
      value={Estado[Name]}
      onChange={Changed}
      type={Type}
      disabled={Disabled}
      multiline={Multiline}
      InputLabelProps={{
        style: { color: 'green' },
      }}
    ></TextField>
  );
};
export default Input;
