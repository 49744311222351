import axios from 'axios';
import { useState } from 'react';
import SwalALert from '../Componentes/SweetAlert';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { ConsultaUsuariosUrl } from '../ConsultaApi/Endpoints/EndPoints';

export function ConsultaUsuarios() {
  const [DataApi, setDataApi] = useState([]);
  const ConsultaUsu = () => {
    axios
      .get(ConsultaUsuariosUrl, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((Response) => {
        setDataApi(Response.data.result);
      })
      .catch((err) => {
        SwalALert({ Info: err.code, Estado: 'No hay acceso al servidor.' });
      });
  };
  useEffect(() => {
    ConsultaUsu();
  }, []);

  const Desbloquear = (e) => {
    localStorage.setItem('Data', JSON.stringify(DataApi[e.target.id]));
    window.location.href = '#/usuariodetalle';
  };
  return (
    <Container className="Tabla">
      <table className="table table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nombre</th>
            <th scope="col">Usuario</th>
            <th scope="col">Administrar</th>
          </tr>
        </thead>
        <tbody>
          {DataApi.map((value, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{value.nombre}</td>
                <td>{value.usuario}</td>
                <td>
                  {
                    <Button id={index} onClick={Desbloquear} color="primary">
                      Ver
                    </Button>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
const Container = styled.div`
  width: 90%;
  padding: auto;
  padding-left: 50px;
  padding-top: 15px;
  text-align: center;
`;
