import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { FormularioAsesor } from './Endpoints/EndPoints';

const EnvioFormularioAsesor = async (DatosFormulario) => {
  var EstadoEnvio = '';
  await axios
    .post(
      FormularioAsesor,
      {
        DatosFormulario,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((response) => {
      if (response.data.Status === 200) {
        SwalALert({
          Info: 'CorrectoRegistro',
          Estado: response.data.Info,
        });
        EstadoEnvio = 'Ok';
      } else {
        SwalALert({
          Info: response.data,
          Estado: 'Error Al Registrar En La Base De Datos',
        });
        EstadoEnvio = 'Error';
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return EstadoEnvio;
};
export default EnvioFormularioAsesor;
