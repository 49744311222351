import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import Cookies from 'universal-cookie';
import { LoginUrl } from './Endpoints/EndPoints';

const InicioSesion = () => {
  const cookies = new Cookies();
  const FormularioLogin = document.forms['FormularioLogin'];
  document.querySelector('form').addEventListener('submit', (e) => {
    e.preventDefault();
    const DatosInicio = Object.fromEntries(new FormData(e.target));
    FormularioLogin.reset();

    if (DatosInicio.Usuario && DatosInicio.Password) {
      
      axios
        .post(LoginUrl, {
          DatosInicio,
        })
        .then((Response) => {
          if (Response.data.username) {
            cookies.set('username', Response.data.username, {
              path: '/',
              expire: 0,
            });
            cookies.set('acceso', Response.data.acceso, {
              path: '/',
              expire: 0,
            });
            window.location.href = '/';
          } else {
            SwalALert(Response.data);
          }
        })
        .catch((err) => {
          SwalALert({ Info: err.code, Estado: 'No hay acceso al servidor.' });
        });
    }
  });
};
export default InicioSesion;
