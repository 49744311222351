import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './RutasProtegidas';
import LoginInicio from '../Formularios/Login';
import DashBoard from '../Formularios/DashBoard';
import Cookies from 'universal-cookie';
import AdminRegistroUsuarios from '../Formularios/AdminRegistroUsuarios';
import { InformePsicologico } from '../Formularios/InformePsicologico';
import { Adultos } from '../Formularios/AnamnesisAdultos';
import { Adolescente } from '../Formularios/AnamnesisAdolescente';
import { Ninos } from '../Formularios/AnamnesisNinos';
import CerrarSesion from '../Formularios/Salir';
import { ConsultaUsuarios } from '../Formularios/ConsultaUsuarios';
import { UsuarioDetalle } from '../Formularios/UsuarioDetalle';
import { ConsultaPacientes } from '../Formularios/ConsultaPacientes';
import { NinosDetalle } from '../FormulariosDetalle/DetalleNinos';
import { FormularioAsesor } from '../Formularios/FormularioAsesor';

const RutasFrontend = () => {
  const cookies = new Cookies();
  const cooUsername =
    cookies.get('username') === undefined ? '' : cookies.get('username');
  const cooAcceso = cookies.get('acceso');
  const RenderComp = cooUsername === '' ? <LoginInicio /> : <DashBoard />;

  return (
    <Routes>
      <Route path="/" element={RenderComp}></Route>
      <Route
        element={
          <ProtectedRoute
            IsAllow={!!cooUsername && cooAcceso === 'Administrador'}
          />
        }
      >
        <Route
          path="/registrousuarios"
          element={<AdminRegistroUsuarios />}
        ></Route>
        <Route path="/consultausuarios" element={<ConsultaUsuarios />}></Route>
        <Route path="/usuariodetalle" element={<UsuarioDetalle />}></Route>
      </Route>
      <Route
        element={
          <ProtectedRoute
            IsAllow={
              (!!cooUsername && cooAcceso === 'Usuario') ||
              cooAcceso === 'Administrador'
            }
          />
        }
      >
        <Route path="/informe" element={<InformePsicologico />}></Route>
        <Route path="/adultos" element={<Adultos />}></Route>
        <Route path="/adolescentes" element={<Adolescente />}></Route>
        <Route path="/ninos" element={<Ninos />}></Route>
        <Route path="/ninosdetalles" element={<NinosDetalle />}></Route>
        <Route path="/formularioasesor" element={<FormularioAsesor />}></Route>

        <Route path="/salir" element={<CerrarSesion />}></Route>
        <Route
          path="/consultapacientes"
          element={<ConsultaPacientes />}
        ></Route>
      </Route>
    </Routes>
  );
};
export default RutasFrontend;
