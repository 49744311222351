import Cookies from 'universal-cookie';

const CerrarSesion = () => {
  const cookies = new Cookies();

  cookies.remove('acceso');
  cookies.remove('username');

  window.location.href = '/';
};
export default CerrarSesion;
