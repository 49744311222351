import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { ActualizarUsuarioUrl } from './Endpoints/EndPoints';

export const ActualizarUsuario = async (DataUsuario) => {
  var EstadoEnvio = '';

  await axios
    .post(
      ActualizarUsuarioUrl,
      {
        DataUsuario,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((response) => {
      if (response.data.Status === 200) {
        SwalALert({
          Info: 'Actualización',
          Estado: response.data.Info,
        });
        EstadoEnvio = 'Ok';
      } else {
        SwalALert({ Info: response.data, Estado: response.data.Error });
        EstadoEnvio = 'Error';
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return EstadoEnvio;
};
