import { Fragment, useState } from 'react';
import Calendario from '../Componentes/Calendario';
import '../Style/FormularioAdmin.css';
import Input from '../Componentes/TextField';
import Selector from '../Componentes/Selector';
import { Button } from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import useFormularioAdmin from '../ConsultaApi/RegistroUsuariosAdmin';

const AdminRegistroUsuarios = () => {
  const ListaAcceso = ['Usuario Psicología','Usuario Asesor', 'Administrador'];
  const ListaEstado = ['Activo', 'Inactivo'];

  const AutoComplete = 'off';
  const valoresIniciales = {
    Cedula: '',
    Nombre: '',
    Apellido: '',
    Usuario: '',
    Correo: '',
    Contraseña: '',
    ConfirmarContraseña: '',
    Acceso: '',
    Estado: 'Activo',
  };

  const [DataFormulario, setDataFormulario] = useState(valoresIniciales);

  const ReiniciarFormulario = () => {
    setDataFormulario(valoresIniciales);
  };

  const EnvioData = () => {
    useFormularioAdmin(DataFormulario).then((response) => {
      if (response === 'Ok') {
        ReiniciarFormulario();
      }
    });
  };
  return (
    <Fragment>
      <div className="FormularioAdmin">
        <div className="BordeFormulario">
          <div className="FormularioColumnas">
            <Calendario Formato="DD/MM/YYYY" Name="Fecha" Estado={DataFormulario}
              SetEstado={setDataFormulario}></Calendario>
            <Input
              Label="N° Cédula"
              Id="Cedula"
              Name="Cedula"
              Focus="true"
              Required={true}
              Type={'number'}
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Nombre"
              Id="Nombre"
              Name="Nombre"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
            <Input
              Label="Apellido"
              Id="Apellido"
              Name="Apellido"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Usuario"
              Id="Usuario"
              Name="Usuario"
              Focus="true"
              Required={true}
              Type={'text'}
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
            <Input
              Label="Correo"
              Id="Correo"
              Name="Correo"
              Focus="true"
              Required={true}
              Type={'email'}
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Input
              Label="Contraseña"
              Focus="true"
              Required={true}
              Name="Contraseña"
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
            <Input
              Label="Confirmar Contraseña"
              Focus="true"
              Required={true}
              Name="ConfirmarContraseña"
              autoComplete={AutoComplete}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Input>
          </div>
          <div className="FormularioColumnas">
            <Selector
              Id="Acceso"
              Disabled={false}
              Name="Acceso"
              Lista={ListaAcceso}
              Value={''}
              Required={true}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Selector>
            <Selector
              Id="Estado"
              Disabled={false}
              Name="Estado"
              Lista={ListaEstado}
              Value={ListaEstado[0]}
              Required={true}
              Estado={DataFormulario}
              SetEstado={setDataFormulario}
            ></Selector>
          </div>
        </div>

        <div className="Botones">
          <Button
            variant="contained"
            type="submit"
            onClick={EnvioData}
            color="success"
            endIcon={<SaveSharpIcon />}
            name="Boton"
          >
            Guardar
          </Button>
          <Button
            variant="contained"
            type="reset"
            color="error"
            onClick={ReiniciarFormulario}
            endIcon={<CancelPresentationSharpIcon />}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
export default AdminRegistroUsuarios;
