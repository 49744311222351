import { useState } from 'react';
import '../Style/FormularioAdmin.css';
import Input from '../Componentes/TextField';
import { Button } from '@mui/material';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelPresentationSharpIcon from '@mui/icons-material/CancelPresentationSharp';
import EnvioFormularioAsesor from '../ConsultaApi/EnvioFormularioAsesor';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import es from 'dayjs/locale/es';
import ConsultaPacientes from '../ConsultaApi/ConsultarPaciente';

export const FormularioAsesor = () => {
  const cookies = new Cookies();
  const cooUsername = cookies.get('username');

  const AutoComplete = 'off';
  const valoresIniciales = {
    Fecha: dayjs().locale(es).format('YYYY-MM-DD'),
    Cedula: '',
    NombreCompleto: '',
    Telefono: '',
    Correo: '',
    Direccion: '',
    MotivoConsulta: '',
    Asesor: '',
    Observaciones: '',
  };

  const [DataFormulario, setDataFormulario] = useState(valoresIniciales);

  const ReiniciarFormulario = () => {
    setDataFormulario(valoresIniciales);
    setDisabled(true);
  };

  const EnvioData = () => {
    EnvioFormularioAsesor(DataFormulario).then((response) => {
      if (response === 'Ok') {
        ReiniciarFormulario();
      }
    });
  };

  const [Disabled, setDisabled] = useState(true);

  const ConsultaDatos = () => {
    ConsultaPacientes(DataFormulario.Cedula)
      .then((response) => {
        if (response !== 'No Se Encuentra Registrado.') {
          setDataFormulario({
            Cedula: response[0].Cedula,
            NombreCompleto: response[0].NombreCompleto,
            Telefono: response[0].Telefono,
            Correo: response[0].Correo,
            Direccion: response[0].Direccion,
            MotivoConsulta: '',
            Observaciones: '',
            Fecha: valoresIniciales.Fecha,
            Asesor: '',
          });
          setDisabled(false);
        } else {
          setDataFormulario({
            ...DataFormulario,
            NombreCompleto: '',
            Telefono: '',
            Correo: '',
            Direccion: '',
            MotivoConsulta: '',
            Observaciones: '',
            Asesor: '',
          });
          setDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Container>
      <h1>Asesorías</h1>
      <h6 className="Usuario">Usuario: {cooUsername}</h6>
      <div className="ClaseFormulario">
        <Input
          Label="N° Cédula"
          Id="Cedula"
          Name="Cedula"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
        ></Input>
        <Button onClick={ConsultaDatos}>Consulta</Button>
        <Input
          Label="Nombre Completo"
          Id="Nombre"
          Name="NombreCompleto"
          Focus="true"
          Required={true}
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Teléfono"
          Id="Teléfono"
          Name="Telefono"
          Focus="true"
          Required={true}
          Type={'number'}
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Correo"
          Id="Correo"
          Name="Correo"
          Focus="true"
          Required={true}
          Type={'email'}
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Dirección"
          Id="Dirección"
          Focus="true"
          Required={true}
          Name="Direccion"
          Type={'text'}
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Motivo Consulta"
          Id="Motivo Consulta"
          Focus="true"
          Type={'text'}
          Required={true}
          Name="MotivoConsulta"
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Asesor"
          Id="Asesor"
          Focus="true"
          Type={'text'}
          Required={true}
          Name="Asesor"
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Disabled={Disabled}
        ></Input>
        <Input
          Label="Observaciones"
          Id="Observaciones"
          Focus="true"
          Type={'text'}
          Required={true}
          Name="Observaciones"
          autoComplete={AutoComplete}
          Estado={DataFormulario}
          SetEstado={setDataFormulario}
          Multiline={true}
          Disabled={Disabled}
        ></Input>
      </div>
      <div className="ClaseFormulario">
        <Button
          variant="contained"
          type="submit"
          onClick={EnvioData}
          color="success"
          endIcon={<SaveSharpIcon />}
          name="Boton"
        >
          Guardar
        </Button>
        <Button
          variant="contained"
          type="reset"
          color="error"
          onClick={ReiniciarFormulario}
          endIcon={<CancelPresentationSharpIcon />}
        >
          Cancelar
        </Button>
      </div>
    </Container>
  );
};
const Container = styled.div`
  .Calendario {
    display: grid;
    width: 100%;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  .ClaseFormulario {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-self: center;
    justify-content: center;
    gap: 1em;
    margin-top: 15px;
    margin-left: 80px;
    margin-right: 80px;
  }
  h3 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  h1 {
    background: #f0f8ff;
    text-align: center;
    color: ${(props) => props.theme.text};
  }
  .Usuario {
    text-align: right;
  }
`;
