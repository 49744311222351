import axios from 'axios';
import SwalALert from '../Componentes/SweetAlert';
import { ConsultaPacienteCedula } from './Endpoints/EndPoints';

const ConsultaPacientes = async (Cedula) => {
  var EstadoEnvio = '';
  await axios
    .post(
      ConsultaPacienteCedula,
      {
        Cedula,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    )
    .then((response) => {
      if (response.data.Status === 200) {
        EstadoEnvio = response.data.Resultado;
      } else {
        SwalALert({
          Info: response.data,
          Estado: response.data.Estado,
        });
        EstadoEnvio = response.data.Estado;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return EstadoEnvio;
};
export default ConsultaPacientes;
