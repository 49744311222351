export const ActualizarUsuarioUrl =
  'https://apisurcos.vercel.app/actualizarusuario';
export const LoginUrl = 'https://apisurcos-r094uaym1-ydc4218.vercel.app/login';
export const ConsultaPacienteCedula =
  'https://apisurcos.vercel.app/consultapacientecedula';
export const Eliminarusuario = 'https://apisurcos.vercel.app/eliminarusuario';
export const FormularioAsesor = 'https://apisurcos.vercel.app/formularioasesor';
export const RegistrarFormulario =
  'https://apisurcos.vercel.app/registrarformulario';
export const AgregarUsuario = 'https://apisurcos.vercel.app/agregarusuario';
export const ConsultaInforme = 'https://apisurcos.vercel.app/consultainforme';
export const ConsultaUsuariosUrl =
  'https://apisurcos.vercel.app/consultausuarios';
export const ConsultaPacientesRango =
  'https://apisurcos.vercel.app/consultapacientes';
